import React from "react";
import "../KnowMore/Know.css";
import { Link } from "react-router-dom";
const Know = () => {
  return (
    <>
      <div className="know-section">
        <div className="left-k">
          <h4>About</h4>
          <h2>Know More About Shree Classes</h2>
          <p>
            Shree Classes is dedicated to nurturing young minds from Nursery to 8th
            grade with a holistic approach to education. Our seasoned educators
            employ innovative teaching methods to make learning engaging and
            effective.{" "}
          </p>
          <p>
            {" "}
            We offer a well-rounded curriculum that includes academic
            excellence, extracurricular activities, and character development.
            At Shree Classes, we believe in fostering curiosity, creativity, and
            a love for learning in every student. Join us to empower your child
            with the skills and confidence to excel academically and beyond.
          </p>
      <Link to="/contact">    <button className="poster-button2">Contact Us</button></Link>
        </div>
        <div className="right-k">
          <img src="ab.png" alt="Know More" />
        </div>
      </div>
    </>
  );
};

export default Know;
