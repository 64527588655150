import './App.css';
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './Components/Chatbox/Chat';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Offlineclasses from './Components/Offlineclasses/Offlineclasses';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/offlineclasses" element={<Offlineclasses />} />
      </Routes>
     
      <Footer />
    </Router>
  );
}

export default App;
