// ChatComponent.js
import React, { useState } from 'react';
import '../Chatbox/Chat.css'
import { IoLogoWhatsapp } from "react-icons/io";
import { RiArrowUpDoubleFill } from "react-icons/ri";
const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');

 
  const handleWhatsAppClick = () => {

    const url = `https://wa.me/+918433137748`;
    window.open(url, '_blank');
  };


  return (
    <>
    <div className="chat-component">
      <div className="messages">
        {/* Render messages here */}
      </div>

      <IoLogoWhatsapp className='icon-chat' onClick={handleWhatsAppClick}/>
    
    </div>
    <div className="chat-component2">
      <div className="messages">
        {/* Render messages here */}
      </div>

      
      <RiArrowUpDoubleFill className='icon-chat2' />
    </div>
    </>
  );
};

export default Chat;
