import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  const phoneClick = () => {
    window.location.href='tel:+919555768358';
  };
  return (
    <>
      <div>
      {/*-  <header>
         <img src='logo.png' alt='logo' className='logo-header'/>
          <h1 className='head-h1'><span className='head-h1-span'>NIMBUS </span>THE PALM VILLAGE</h1>
          <p className='head-p'>AT SECTOR 22A, YAMUNA EXPRESSWAY</p>
          <p onClick={phoneClick} className='h'>+919555768358</p>
        </header>
        -*/}
        
        <nav>
        <Link to="/">
        <img src='logo2.jpeg' alt='logo' className='logo-s'/>
        </Link>
          <ul className={isOpen ? 'active' : ''}>
            <Link to="/" onClick={closeMenu}><li>Home</li></Link>
            <Link to="/offlineclasses" onClick={closeMenu}><li>Offline Classes</li></Link>
            <Link to="/aboutus" onClick={closeMenu}><li>About Us </li></Link>
            
            <Link to="/contact" onClick={closeMenu}><li>Contact</li></Link>
           
            {/*-
            <a href="#vst" onClick={closeMenu}><li>Virtual Site Tour</li></a>
           
            <a href="#br" onClick={closeMenu}><li>Brochure</li></a>
             -*/}
            
          </ul>
         
          <div className="handle" onClick={toggleMenu}>
            {isOpen ? 'Close' : 'Menu'}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
