import React, { useState } from 'react';
import './Home.css';
import Poster from '../Poster/Poster';
import Why from '../Why/Why';
import Course from '../Course/Course';
import Know from '../KnowMore/Know';
import Popup from '../Popup/Popup'; // Import the Popup component
import Count from '../Count/Count';

const Home = () => {
  const [isPopupVisible, setPopupVisible] = useState(true);

  
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };


  
  return (
    <>
      <Popup togglePopup={togglePopup} isVisible={isPopupVisible} />
      <Poster />
      <Know />
      <Course />
      <Why />
      {/*-
      <Count />
      -*/}
    </>
  );
};

export default Home;
