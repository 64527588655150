import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../Popup/Popup.css'; // Ensure this CSS file exists and is properly linked

const Popup = ({ togglePopup, isVisible }) => {
  const [formData, setFormData] = useState({ name: '', mobile: '', category: '' });
  const [errors, setErrors] = useState({ name: '', mobile: '', category: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateName = (name) => {
    if (!/^[A-Za-z]+$/.test(name)) {
      return 'Name should contain only alphabets';
    }
    return '';
  };

  const validateMobile = (mobile) => {
    if (!/^\d{10}$/.test(mobile)) {
      return 'Mobile should be a 10-digit number';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Perform real-time validation
    if (name === 'name') {
      setErrors({ ...errors, name: validateName(value) });
    } else if (name === 'mobile') {
      setErrors({ ...errors, mobile: validateMobile(value) });
    }
  };

  const validateForm = () => {
    const nameError = validateName(formData.name);
    const mobileError = validateMobile(formData.mobile);
    const categoryError = formData.category ? '' : 'Please select a category';

    setErrors({ name: nameError, mobile: mobileError, category: categoryError });

    return !nameError && !mobileError && !categoryError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      emailjs.sendForm('service_6up5va3', 'template_gkf7ywk', e.target, 'emN7GgbW6_sr8YC7h')
        .then((result) => {
          console.log(result.text);
          alert('Form submitted successfully!');
          setIsSubmitting(false);
          togglePopup();
        }, (error) => {
          console.log(error.text);
          alert('Failed to submit the form. Please try again.');
          setIsSubmitting(false);
        });
    }
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="card-connect2">
        <button className="close-button" onClick={togglePopup}>×</button>
        <h3 className='h3-c'>Connect with Us</h3>
        <form className='form-p' autocomplete="off" onSubmit={handleSubmit}>
          <label>
            Name:
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
            {errors.name && <span className="error-message">{errors.name}</span>}
          </label>
          <label>
            Mobile:
            <input 
              type="tel" 
              name="mobile" 
              value={formData.mobile} 
              onChange={handleChange} 
              required 
            />
            {errors.mobile && <span className="error-message">{errors.mobile}</span>}
          </label>
          <div className='b-p'>
            <label>
              <input 
                type="radio" 
                name="category" 
                value="parents" 
                checked={formData.category === 'parents'} 
                onChange={handleChange} 
                required 
              />
              Parents
            </label>
            <label>
              <input 
                type="radio" 
                name="category" 
                value="students" 
                checked={formData.category === 'students'} 
                onChange={handleChange} 
                required 
              />
              Students
            </label>
            {errors.category && <span className="error-message">{errors.category}</span>}
          </div>
          <button type="submit" className="connect-button" disabled={isSubmitting}>Connect</button>
        </form>
        <img src="c.svg" alt="Small" className="small-img" />
      </div>
    </div>
  );
};

export default Popup;
