import React, { useState } from 'react';
import '../Poster/Poster.css';
import emailjs from 'emailjs-com';

const Poster = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    category: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    mobile: ''
  });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validateMobile = (mobile) => /^\d{10}$/.test(mobile);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'name') {
      if (!validateName(value)) {
        setErrors({ ...errors, name: 'Name should only contain alphabets' });
      } else {
        setErrors({ ...errors, name: '' });
      }
    }

    if (name === 'mobile') {
      if (!validateMobile(value)) {
        setErrors({ ...errors, mobile: 'Mobile number should be 10 digits' });
      } else {
        setErrors({ ...errors, mobile: '' });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateName(formData.name) || !validateMobile(formData.mobile)) {
      return;
    }

    setSubmitDisabled(true);

    emailjs.sendForm('service_6up5va3', 'template_gkf7ywk', e.target, 'emN7GgbW6_sr8YC7h')
      .then((result) => {
        alert('Message sent successfully!');
        setSubmitDisabled(false);
      }, (error) => {
        alert('Failed to send message, please try again.');
        setSubmitDisabled(false);
      });
  };

  return (
    <>
      <div className="poster-container">
        <div className="left-p">
          <div className="image-container">
            <img src="left.jpg" alt="Image 1" className="image image1" />
            <img src="right.jpg" alt="Image 2" className="image image2" />
            <img src="comm.jpg" alt="Image 4" className="image image4" />
            <img src="fixed.jpg" alt="Image 3" className="image image3 fixed-image" />
          </div>
        </div>
        <div className="right-p">
          <h2>Ignite Your Child’s Learning Journey with <span className='p-m'> Shree Classes!</span></h2>
          <p>Shree Classes offers expert coaching for students in classes Nursery to 8, focusing on personalized instruction and academic excellence. Empower your child with a solid foundation and a passion for learning today!</p>
          <button className="poster-button" onClick={togglePopup}>Connect With Us</button>
        </div>
      </div>

      {showPopup && (
        <div className="card-connect2">
          <button className="close-button" onClick={togglePopup}>×</button>
          <h3 className='h3-c'>Connect with Us</h3>
          <form className='form-p' autocomplete="off" onSubmit={handleSubmit}>
            <label>
              Name:
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              {errors.name && <p className="error">{errors.name}</p>}
            </label>
            <label>
              Mobile:
              <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} required />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </label>
            <div className='b-p'>
              <label>
                <input type="radio" name="category" value="parents" onChange={handleChange} required />
                Parents
              </label>
              <label>
                <input type="radio" name="category" value="students" onChange={handleChange} required />
                Students
              </label>
            </div>
            <button type="submit" className="connect-button" disabled={submitDisabled}>Connect</button>
          </form>
          <img src="c.svg" alt="Small" className="small-img" />
        </div>
      )}
    </>
  );
};

export default Poster;
