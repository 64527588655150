import React from "react";
import "../Footer/Footer.css";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,

} from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
const Footer = () => {
  return (
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo-details">
           {/*-
            <span className="logo_name">Shree Classes</span>
            -*/}
            <Link to="/">
            <img src="logo-f.png" alt="logo" className="logo-f2"/>
            </Link>
          </div>
          <div className="media-icons">
            <a href="https://www.facebook.com/profile.php?id=61563048567859" target="_blank" aria-label="Facebook">
              <FaFacebookF />
            </a>

            <a href="https://www.instagram.com/shreeclassesbareilly/" target="_blank" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://wa.me/+918433137748"  target="_blank" aria-label="Instagram">
              <IoLogoWhatsapp />
            </a>
          </div>
        </div>
        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">About</li>
            <p className="ab-f-t">
              Shree Classes offers expert coaching for students in classes Nursery
              to 8, focusing on personalized instruction and academic
              excellence.{" "}
            </p>
          </ul>
          <ul className="box">
            <li className="link_name">Our Links</li>
            <li>
            <Link to="/"> <p className="p-l">Home</p> </Link>
            </li>
            <li>
            <Link to="/offlineclasses"> <p className="p-l">Offline Classes</p></Link>
            </li>
            <li>
            <Link to="/aboutus">  <p className="p-l">About us</p></Link>
            </li>
            <li>
            <Link to="/contact">  <p className="p-l">Contact Us</p></Link>
            </li>
          </ul>

          <ul className="box">
            <li className="link_name">We Provides</li>
            <li>
              <p>Monthly Test Series</p>
            </li>
            <li>
              <p>Extra Time For Weak Students</p>
            </li>
            <li>
              <p>Monthly Student Report</p>
            </li>
            <li>
              <p>Progress Monitoring</p>
            </li>
          </ul>
         {/*-
          <ul className="box">
            <li className="link_name">Courses</li>
            <li>
              <p>Mathematics</p>
            </li>
            <li>
              <p>Science</p>
            </li>
            <li>
              <p>Computer Science</p>
            </li>
            <li>
              <p>Geography</p>
            </li>
            <li>
              <p>Biology</p>
            </li>
          </ul>
          -*/}
        </div>
      </div>
      <div className="bottom-details">
        <div className="bottom_text">
          <span className="copyright_text">
          Copyright © 2024 Shree Classes. Powered by <a href="https://infogainsoft.com" target="_blank">Infogainsoft</a>

          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
