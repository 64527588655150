import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaPlayCircle,
  FaAward,
  FaUserGraduate,
  FaHistory,
} from "react-icons/fa";
import "../Why/Why.css";

const Why = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
    const video = document.getElementById("why-video");
    video.play();
  };
  return (
    <>
      <div className="why-container-sp">
        <div className="left-why-sp">
          <h4 className="heading-h4-sp">Why Choose Us</h4>
          <h2 className="heading-h2-sp">Our Commitment to Excellence</h2>
          <p className="paragraph-sp">
          At Shree Classes, we offer expert educators guiding students from classes Nursery to 8, ensuring personalized attention and a proven track record of success. Our comprehensive curriculum is tailored for holistic development, making us the ideal choice for your child's educational journey. With state-of-the-art facilities and a nurturing environment, we foster a love for learning. Our innovative teaching methods are designed to inspire and engage. Join us to give your child the foundation they need to excel.
          </p>
          {/*-
          <div className="icons-container-sp">
            <div className="icon-block-sp">
              <FaAward className="icon-sp" />
              <div className="icon-text-sp2">
                <p className="icon-number-sp2">50+</p>
                <p className="he-why2">Awards</p>
              </div>
            </div>
            <div className="icon-block-sp">
              <FaUserGraduate className="icon-sp" />
              <div className="icon-text-sp2">
                <p className="icon-number-sp2">10K+</p>
                <p className="he-why2">Students</p>
              </div>
            </div>
            <div className="icon-block-sp">
              <FaHistory className="icon-sp" />
              <div className="icon-text-sp2">
                <p className="icon-number-sp2">20+</p>
                <p className="he-why2">Years of History</p>
              </div>
            </div>
          </div>
          -*/}
          <div className="bt-c">
            <Link to="/contact">
              {" "}
              <button className="poster-button3">Connect With Us</button>
            </Link>
          </div>
        </div>
        <div className="right-why-sp">
          <div className={`video-overlay-sp ${isPlaying ? "playing-sp" : ""}`}>
            <video id="why-video" className="video-sp" src="why.mp4" />
            {!isPlaying && (
              <FaPlayCircle className="play-button-sp" onClick={handlePlay} />
            )}
          </div>
        </div>
      </div>
      <div className="services-section-sp">
        <h2 className="services-heading-sp">We Provided for our Students</h2>
        <ul className="services-list-sp">
          <li>Monthly test series</li>
          <li>Extra time for weak students</li>
          <li>Monthly student report</li>
          <li>Progress monitoring</li>

          <li>Activity-based learning on every Sunday</li>
          <li>2-hour class for each student</li>
        </ul>
      </div>
    </>
  );
};

export default Why;
