import React from 'react'
import '../About/About.css'
import Why from '../Why/Why'
import Topper from '../Topper/Topper'
import Count from '../Count/Count'
const About = () => {
  return (
    <>
<section className="about-g">

      <div className="left-ga">
      <div className='firs'>
        <h4 className='a-b-h'>About Shree Classes</h4>
        <p className="text1">At Shree Classes, we are dedicated to nurturing young minds and providing a strong educational foundation for students in classes Nursery to 8. Our experienced and passionate teachers employ innovative teaching methods to make learning engaging and effective. We focus on creating a supportive and interactive environment where students feel encouraged to explore their curiosity and develop a love for learning.</p>
        </div>
        <p className="text2">We understand that each child has unique needs and learning styles. That’s why our tailored approach ensures that every student receives personalized attention and support to excel in their studies. From building fundamental skills in mathematics and science to enhancing language proficiency and critical thinking, Shree Classes is committed to helping students achieve their full potential and gain confidence in their academic journey.</p>
      </div>
      <div className="right-ga">
        <img src="about.jpg" alt="About" />
      </div>
     
    </section>
    <Why/>
    {/*-
    <Topper/>
    <Count/>
    -*/}
    </>
  )
}

export default About