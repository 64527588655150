import React, { useState } from 'react';
import '../Offlineclasses/Offlineclasses.css';
import Topper from '../Topper/Topper';
import emailjs from 'emailjs-com';

const Offlineclasses = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedClass, setSelectedClass] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: ''
       
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleClassSelect = (className) => {
        setSelectedClass(className);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let errorMsg = '';
        switch (name) {
            case 'name':
                if (!/^[A-Za-z\s]+$/.test(value)) {
                    errorMsg = 'Name should only contain alphabets.';
                }
                break;
            case 'mobile':
                if (!/^\d{10}$/.test(value)) {
                    errorMsg = 'Mobile number should be 10 digits.';
                }
                break;
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    errorMsg = 'Invalid email address.';
                }
                break;
            case 'city':
                if (!/^[A-Za-z\s]+$/.test(value)) {
                    errorMsg = 'City should only contain alphabets.';
                }
                break;
            case 'message':
                if (value.length < 15) {
                    errorMsg = 'Message should be at least 15 characters long.';
                }
                break;
            default:
                break;
        }
        setErrors({
            ...errors,
            [name]: errorMsg
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                formErrors[key] = 'This field is required';
            } else {
                validateField(key, formData[key]);
            }
        });
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            setIsSubmitting(true);

            // Append selected class to the form data
            const finalFormData = {
                ...formData,
                selectedClass
            };

            emailjs.sendForm('service_6up5va3', 'template_xir23mu', e.target, 'emN7GgbW6_sr8YC7h')
                .then((result) => {
                    console.log(result.text);
                    alert('Form submitted successfully!');
                    setIsSubmitting(false);
                }, (error) => {
                    console.log(error.text);
                    alert('Failed to submit the form. Please try again.');
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <>
            <div className="offline-div-sp">
                <h2 className="offline-heading-sp">Offline Learning Experience</h2>
                <p className="offline-text-sp">
                    Our offline learning experience provides a hands-on approach to education. Students engage in interactive sessions and practical workshops, ensuring a comprehensive understanding of the subjects.
                </p>
            </div>

            <section className="wrapper-full tab-container effectTab-header" id="style_1" style={{ paddingTop: 0 }}>
                <div className='di-ti'>
                    <h1>
                        Best Coaching Institute in Bareilly
                    </h1>
                </div>
                <div className="wrapper tab-item">
                    <div className="custom-row">
                        <div className="column-12 column-xs-12 column-sm-12 tab">
                            <div className="custom-row">
                                <div className="column-4 column-md-6 column-xs-12  box-tab">
                                    <div className="effect effect-one">
                                        <img src="https://img.freepik.com/free-photo/full-shot-kids-sitting-together-table_23-2149355252.jpg?t=st=1721646616~exp=1721650216~hmac=b76d7637101994c936583f51ca04269ecb391326584adf686477c078647da588&w=900" className="img-fluid" />
                                        <div className="tab-text">
                                            <h2>Nursery</h2>
                                            <p> A nurturing and playful environment for early childhood development, fostering social, emotional, and cognitive growth.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-8 column-md-6   box-tab">
                                    <div className="effect grid-width8 effect-one">
                                        <img src="https://img.freepik.com/premium-photo/group-people-table_1048944-22284815.jpg?w=900" className="img-fluid" />
                                        <div className="tab-text">
                                            <h2 className="pt-1">LKG - UKG</h2>
                                            <p>Engaging curriculum blending academics with creative exploration, building a strong foundation in language, numeracy, and physical skills.</p>
                                            <div className="icons-block"> <a href="#" className="social-icon-1"><i className="fa fa-facebook-official" /></a> <a href="#" className="social-icon-2"><i className="fa fa-twitter-square" /></a> <a href="#" className="social-icon-3"><i className="fa fa-youtube-square" /></a> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column-6 column-md-6  box-tab">
                                    <div className="effect grid-width6 effect-one">
                                        <img src="https://img.freepik.com/premium-photo/discover-your-passion-apply-now_1029473-221016.jpg?w=900" className="img-fluid" />
                                        <div className="tab-text">
                                            <h2 className="pt-2">1 - 5 Classes</h2>
                                            <p>Balanced education with innovative teaching methods, covering core subjects and fostering critical thinking and creativity. </p>
                                            <div className="icons-block"> <a href="#" className="social-icon-1"><i className="fa fa-facebook-official" /></a> <a href="#" className="social-icon-2"><i className="fa fa-twitter-square" /></a> <a href="#" className="social-icon-3"><i className="fa fa-youtube-square" /></a> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="column-6 column-md-6  box-tab">
                                    <div className="effect grid-width6 effect-one">
                                        <img src="https://img.freepik.com/premium-photo/smiling-arabic-schoolgirl-sitting-desk-elementary-school-classroom-writing-notebook-posing-looking-camera-multi-ethnic-classmates-background_776674-270146.jpg?w=740" className="img-fluid" />
                                        <div className="tab-text">
                                            <h2 className="pt-2">5 - 8 Classes</h2>
                                            <p>Deepening subject knowledge and enhancing analytical skills, preparing students for higher education and future success. </p>
                                            <div className="icons-block"> <a href="#" className="social-icon-1"><i className="fa fa-facebook-official" /></a> <a href="#" className="social-icon-2"><i className="fa fa-twitter-square" /></a> <a href="#" className="social-icon-3"><i className="fa fa-youtube-square" /></a> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<Topper />*/}
            <div className="enroll-en" style={{ height: '40vh' }}>
                <div className="left-enroll-en">
                    <h3>Enroll Today</h3>
                    <p>Join our classes and start your learning journey with us.</p>
                </div>
                <div className="right-enroll-en">
                    <button className="apply-button-en" onClick={togglePopup}>Apply Now</button>
                </div>
                {showPopup && (
                    <div className="popup-form-en">
                        <div className="popup-content-en">
                            <h3 className="popup-header-en">Apply for Enrollment</h3>
                            <div className="class-options-en">
                                <button
                                    className={`class-toggle-en ${selectedClass === "Nursery" ? "active-en" : ""}`}
                                    onClick={() => handleClassSelect("Nursery")}
                                >
                                    Nursery
                                </button>
                                <button
                                    className={`class-toggle-en ${selectedClass === "UKG-LKG" ? "active-en" : ""}`}
                                    onClick={() => handleClassSelect("UKG-LKG")}
                                >
                                    UKG-LKG
                                </button>
                                <button
                                    className={`class-toggle-en ${selectedClass === "1-5 Class" ? "active-en" : ""}`}
                                    onClick={() => handleClassSelect("1-5 Class")}
                                >
                                    1-5 Class
                                </button>
                                <button
                                    className={`class-toggle-en ${selectedClass === "5-8 Class" ? "active-en" : ""}`}
                                    onClick={() => handleClassSelect("5-8 Class")}
                                >
                                    5-8 Class
                                </button>
                            </div>
                            <form className="enroll-form-en" autocomplete="off" onSubmit={handleSubmit}>
                                <div className='fex'>
                                    <label className="form-label-en">Name</label>
                                    <input
                                        type="text4"
                                        name="name"
                                        autocomplete="off"
                                        className="form-input-en"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                   
                                    <label className="form-label-en">Mobile</label>
                                    <input
                                        type="tel4"
                                        name="mobile"
                                        className="form-input-en"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        required
                                    />
                                   
                                </div>
                                <div className='fex2'>
                                {errors.name && <p className="error-text-en">{errors.name}</p>}
                                {errors.mobile && <p className="error-text-en">{errors.mobile}</p>}
                                </div>
                                <div className='fex'>
                                    <label className="form-label-en">Email</label>
                                    <input
                                        type="email4"
                                        name="email"
                                        className="form-input-en"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                   
                                    <label className="form-label-en">City</label>
                                    <input
                                        type="text4"
                                        name="city"
                                        className="form-input-en"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                    />
                                  
                                </div>
                                <div className='fex2'>
                                {errors.email && <p className="error-text-en">{errors.email}</p>}
                                {errors.city && <p className="error-text-en">{errors.city}</p>}
                                </div>
                                <label className="form-label-en">Message</label>
                                <textarea
                                    name="message"
                                    className="form-textarea-en"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                {errors.message && <p className="error-text-en">{errors.message}</p>}

                                {/* Hidden input field for selected class */}
                                <input type="hidden" name="selectedClass" value={selectedClass} />
                                
                                <button type="submit" className="submit-button-en" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </form>
                            <button className="close-button-en" onClick={togglePopup}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Offlineclasses;
