import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../Contact/Contact.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRequestCall = () => {
    window.location.href = 'tel:+918433137748';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    let error = '';

    if (name === 'name') {
      if (!/^[A-Za-z ]+$/.test(value)) {
        error = 'Name should contain only alphabets';
      }
    } else if (name === 'email') {
      if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
      }
    } else if (name === 'mobile') {
      if (!/^\d{10}$/.test(value)) {
        error = 'Mobile should be a 10-digit number';
      }
    } else if (name === 'message') {
      if (value.length < 10) {
        error = 'Message should be at least 10 characters long';
      }
    }

    setErrors({
      ...errors,
      [name]: error
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors.name && !errors.email && !errors.mobile && !errors.message) {
      setIsSubmitting(true);
      emailjs.send(
        'service_6up5va3', // Replace with your EmailJS service ID
        'template_xir23mu', // Replace with your EmailJS template ID
        formData,
        'emN7GgbW6_sr8YC7h' // Replace with your EmailJS user ID
      ).then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          message: ''
        });
        setIsSubmitting(false);
      }).catch((error) => {
        console.log('FAILED...', error);
        alert('Failed to send message, please try again later.');
        setIsSubmitting(false);
      });
    } else {
      console.log('Form contains errors');
    }
  };

  return (
    <>
      <div className='full-con-sp'>
        <div id="map" className="responsive-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28520.84315623572!2d79.41085178334453!3d28.390999163098204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0068d570079bd%3A0x36d20d546f65b25b!2sVeer%20Savarkar%20Nagar%2C%20Bareilly%2C%20Uttar%20Pradesh%20243122!5e0!3m2!1sen!2sin!4v1721652999295!5m2!1sen!2sin"
            width={600}
            height={450}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
        <div className="contact-sp">
          <div className="left-connect-sp">
            <h4>Contact Us</h4>
            <p>We would love to hear from you. Please fill out the form below and we will get in touch with you shortly.</p>
            <form className="contact-form-sp" autocomplete="off" onSubmit={handleSubmit}>
              <input 
                type="text2" 
                name="name" 
                autocomplete="off"
                placeholder="Name" 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
              {errors.name && <p className="error">{errors.name}</p>}
              
              <input 
                type="email2" 
                name="email" 
                placeholder="Email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
              {errors.email && <p className="error">{errors.email}</p>}
              
              <input 
                type="tel2" 
                name="mobile" 
                placeholder="Mobile" 
                value={formData.mobile} 
                onChange={handleChange} 
                required 
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
              
              <textarea 
                name="message" 
                placeholder="Message" 
                value={formData.message} 
                onChange={handleChange} 
                required 
              ></textarea>
              {errors.message && <p className="error">{errors.message}</p>}
              
              <button type="submit" disabled={isSubmitting}>Submit</button>
            </form>
          </div>
          <div className="right-connect-sp">
            <div className="icon-text-sp">
              <FaMapMarkerAlt className='icon-ccc' size={80} />
              <p>Veer Savarkar Nagar, Near Vyas World School, Bareilly</p>
            </div>
            <div className="icon-text-sp">
              <FaPhoneAlt className='icon-ccc' size={80} />
              <p>+91 8433137748 <br/> +91 9897844122 </p>
            </div>
            <div className="icon-text-sp">
              <FaEnvelope className='icon-ccc' size={80} />
              <p>info@shreeclassesbly.com</p>
            </div>
          </div>
        </div>
        
        <div className="help-section">
          <h1 className="heading-help" data-aos="fade-down">How Can we help you!</h1>
          <p className="paragraph-help" data-aos="fade-down">
            Send us a message to discuss your query in further details, or for information about our services.
          </p>
    
          <button className="button-help" onClick={handleRequestCall}>Request Call</button>
        </div>
      </div>
    </>
  );
}

export default Contact;
