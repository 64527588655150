import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../Course/Course.css';
import { RiEnglishInput } from "react-icons/ri";
import { SiBookstack } from "react-icons/si";
import { TbMathSymbols } from "react-icons/tb";
import { FaBook, FaChalkboardTeacher, FaFlask, FaLaptopCode, FaGlobe, FaMusic, FaPaintBrush, FaDna } from 'react-icons/fa';

const Course = () => {
  const courseRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    courseName: '',
    category: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    mobile: '',
    courseName: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate inputs
    switch (name) {
      case 'name':
        setErrors({
          ...errors,
          name: /^[A-Za-z\s]+$/.test(value) ? '' : 'Name should be alphabets'
        });
        break;
      case 'mobile':
        setErrors({
          ...errors,
          mobile: /^\d{10}$/.test(value) ? '' : 'Mobile number should be 10 digits'
        });
        break;
      case 'courseName':
        setErrors({
          ...errors,
          courseName: /^[A-Za-z\s]+$/.test(value) ? '' : 'Course name should be alphabets'
        });
        break;
      default:
        break;
    }
  };

  const handleCardClick = (course) => {
    setFormData({ ...formData, courseName: course });
    setErrors({ ...errors, courseName: '' }); // Clear any existing error message for courseName
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors.name && !errors.mobile && !errors.courseName && formData.category) {
      setIsSubmitting(true);
      emailjs.sendForm('service_6up5va3', 'template_gkf7ywk', e.target, 'emN7GgbW6_sr8YC7h')
        .then((result) => {
          alert('Message sent successfully!');
          console.log(result.text);
          setIsSubmitting(false);
        }, (error) => {
          alert('Failed to send message, please try again.');
          console.log(error.text);
          setIsSubmitting(false);
        });
    } else {
      alert('Please fix the errors before submitting the form.');
    }
  };

  return (
    <div className="course-section-sp" ref={courseRef}>
      <div className="left-co-sp">
        <h4 className="left-co-heading-sp">Find Your Course</h4>
        <p className="left-co-text-sp">Fill in the below form to find your course</p>
        <form className="course-form-sp" autocomplete="off" onSubmit={handleSubmit}>
          <div className="form-group-sp">
            <label htmlFor="name" className="form-label-sp">Name:</label>
            <input
              type="text3"
              id="name"
              name="name"
              className="form-input-sp"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error-message-sp">{errors.name}</span>}
          </div>
          <div className="form-group-sp">
            <label htmlFor="mobile" className="form-label-sp">Mobile Number:</label>
            <input
              type="text3"
              id="mobile"
              name="mobile"
              className="form-input-sp"
              placeholder="Enter your mobile number"
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && <span className="error-message-sp">{errors.mobile}</span>}
          </div>
          <div className="form-group-sp">
            <label htmlFor="course-name" className="form-label-sp">Course Name:</label>
            <input
              type="text3"
              id="course-name"
              name="courseName"
              className="form-input-sp"
              placeholder="Enter course name"
              value={formData.courseName}
              onChange={handleChange}
            />
            {errors.courseName && <span className="error-message-sp">{errors.courseName}</span>}
          </div>
          <div className="form-group-sp">
            <label htmlFor="category" className="form-label-sp">Category:</label>
            <select
              id="category"
              name="category"
              className="form-select-sp"
              value={formData.category}
              onChange={handleChange}
            >
              <option value="" disabled>Select class</option>
              <option value="1">Nursery</option>
              <option value="2">Class 1</option>
              <option value="3">Class 2</option>
              <option value="4">Class 3</option>
              <option value="5">Class 4</option>
              <option value="6">Class 5</option>
              <option value="7">Class 6</option>
              <option value="8">Class 7</option>
              <option value="9">Class 8</option>
            </select>
          </div>
          <button type="submit" className="form-submit-sp" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
      <div className="right-co-sp">
        <div className="card-grid-sp">
          <div className="card-sp" onClick={() => handleCardClick('Mathematics')}>
            <TbMathSymbols className="card-icon-sp" />
            <p className="card-text-sp">Mathematics</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('Science')}>
            <FaFlask className="card-icon-sp" />
            <p className="card-text-sp">Science</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('Hindi')}>
            <FaBook className="card-icon-sp" />
            <p className="card-text-sp">Hindi</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('Computer')}>
            <FaLaptopCode className="card-icon-sp" />
            <p className="card-text-sp">Computer</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('Social Science')}>
            <FaGlobe className="card-icon-sp" />
            <p className="card-text-sp">Social Science</p>
          </div>
          
          <div className="card-sp" onClick={() => handleCardClick('Art')}>
            <FaPaintBrush className="card-icon-sp" />
            <p className="card-text-sp">Art</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('English')}>
            <RiEnglishInput className="card-icon-sp" />
            <p className="card-text-sp">English</p>
          </div>
          <div className="card-sp" onClick={() => handleCardClick('All Subjects')}>
            <SiBookstack className="card-icon-sp" />
            <p className="card-text-sp">All Subjects</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
